var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-danger" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            "label-width": "120px",
            size: "small",
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择车辆：", prop: "vehId" } },
                    [
                      _c("car-tree", {
                        attrs: { choosedCph: _vm.choosedCph },
                        on: { getData: _vm.getVehIds }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "驾驶员：", prop: "driver" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.driver,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "driver", $$v)
                          },
                          expression: "addForm.driver"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "押运人员：", prop: "escortPerson" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.escortPerson,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "escortPerson", $$v)
                          },
                          expression: "addForm.escortPerson"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "运输日期：", prop: "carriageDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          size: "small",
                          type: "date",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.addForm.carriageDate,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "carriageDate", $$v)
                          },
                          expression: "addForm.carriageDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货物分类：", prop: "cargoClassify" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择货物分类"
                          },
                          on: { change: _vm.getSubs },
                          model: {
                            value: _vm.addForm.cargoClassify,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "cargoClassify", $$v)
                            },
                            expression: "addForm.cargoClassify"
                          }
                        },
                        _vm._l(_vm.modelList, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货物分项：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.addForm.cargoItemize,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "cargoItemize", $$v)
                            },
                            expression: "addForm.cargoItemize"
                          }
                        },
                        _vm._l(
                          _vm.modelSubsList[_vm.addForm.cargoClassify],
                          function(item) {
                            return _c("el-option", {
                              key: item.dictCode,
                              attrs: {
                                label: item.dictValue,
                                value: item.dictCode
                              }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称：", prop: "cargoName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.cargoName,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "cargoName", $$v)
                          },
                          expression: "addForm.cargoName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "运输类型：", prop: "carriageType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.addForm.carriageType,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "carriageType", $$v)
                            },
                            expression: "addForm.carriageType"
                          }
                        },
                        _vm._l(_vm.typeList, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数量：", prop: "amount" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "amount", $$v)
                          },
                          expression: "addForm.amount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数量单位：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.unit,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "unit", $$v)
                          },
                          expression: "addForm.unit"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "起点区域：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.startArea,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "startArea", $$v)
                          },
                          expression: "addForm.startArea"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "终点区域：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.endArea,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "endArea", $$v)
                          },
                          expression: "addForm.endArea"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "线路起点：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.startLine,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "startLine", $$v)
                          },
                          expression: "addForm.startLine"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "途径：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.via,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "via", $$v)
                          },
                          expression: "addForm.via"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "线路终点：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.endLine,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "endLine", $$v)
                          },
                          expression: "addForm.endLine"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "抵达地点：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.arriveAdd,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "arriveAdd", $$v)
                          },
                          expression: "addForm.arriveAdd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "说明：" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "remark", $$v)
                          },
                          expression: "addForm.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }