
<template>
  <div class="body add-danger">
    <el-form
      ref="addForm"
      :model="addForm"
      label-width="120px"
      size="small"
      :rules="rules"
      class="demo-form-inline dialog-form"
    >
      <el-row>
        <el-col :span="8">
          <!-- 选择车辆： -->
          <el-form-item label="选择车辆：" prop="vehId">
            <car-tree @getData="getVehIds" :choosedCph="choosedCph"></car-tree>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="驾驶员：" prop="driver">
            <el-input v-model="addForm.driver"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="押运人员：" prop="escortPerson">
            <el-input v-model="addForm.escortPerson"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="运输日期：" prop="carriageDate">
            <el-date-picker
              class
              size="small"
              v-model="addForm.carriageDate"
              type="date"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="货物分类：" prop="cargoClassify">
            <el-select
              v-model="addForm.cargoClassify"
              clearable
              placeholder="请选择货物分类"
              @change="getSubs"
            >
              <el-option
                v-for="item in modelList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="货物分项：">
            <el-select
              v-model="addForm.cargoItemize"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in modelSubsList[addForm.cargoClassify]"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="名称：" prop="cargoName">
            <el-input v-model="addForm.cargoName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="运输类型：" prop="carriageType">
            <el-select v-model="addForm.carriageType" placeholder="请选择">
              <el-option
                v-for="item in typeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="数量：" prop="amount">
            <el-input v-model="addForm.amount"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="数量单位：">
            <el-input v-model="addForm.unit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="起点区域：">
            <el-input v-model="addForm.startArea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="终点区域：">
            <el-input v-model="addForm.endArea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="线路起点：">
            <el-input v-model="addForm.startLine"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="途径：">
            <el-input v-model="addForm.via"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="线路终点：">
            <el-input v-model="addForm.endLine"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="抵达地点：">
            <el-input v-model="addForm.arriveAdd"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="说明：">
            <el-input v-model="addForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSelectTree.vue'
import {
  formatDate,
  getStartDate,
  checkTimeLimitDay
} from '@/common/utils/index'
import {
  addDangerous,
  editDangerous,
  getDangerousById
} from '@/api/lib/gps-api.js'
import { queryDictsByCodes } from '@/api/lib/api.js'

export default {
  components: {
    carTree
  },
  props: {
    itemId: {
      type: [Number, String]
    },
    cph: {
      type: String
    }
  },
  data() {
    return {
      choosedCph: '',
      isEdit: false,
      addForm: {
        vehId: null,
        amount: null,
        arriveAdd: '',
        cargoClassify: '',
        cargoItemize: '',
        cargoName: '',
        carriageDate: new Date(),
        carriageType: '',
        startArea: '',
        endArea: '',
        startLine: '',
        via: '',
        endLine: '',
        driver: '',
        escortPerson: '',
        remark: '',
        unit: ''
      },

      rules: {
        vehId: [{ required: true, message: '请选择车辆', trigger: 'change' }],
        carriageDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        cargoClassify: [
          { required: true, message: '请选择货物分类', trigger: 'blur' }
        ],
        carriageType: [
          { required: true, message: '请选择运输类型', trigger: 'blur' }
        ],
        cargoName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入数量', trigger: 'blur' }],
        driver: [{ required: true, message: '请输入驾驶员', trigger: 'blur' }],
        escortPerson: [
          { required: true, message: '请输入押运人员', trigger: 'blur' }
        ],
        ivehid: [{ required: true, message: '请选择车辆', trigger: 'change' }]
      },
      modelList: [],
      typeList: [],
      modelSubsList: []
    }
  },
  methods: {
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.addForm.clearValidate()
      this.addForm.vehId = val
    },
    // 新增/修改
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            ...this.addForm
          }
          data.carriageDate = formatDate(this.addForm.carriageDate)
          if (!this.isEdit) {
            addDangerous(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            editDangerous(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia(flag) {
      this.$emit('close', flag)
    },
    /**货物分项置空 */
    getSubs() {
      this.addForm.cargoItemize = ''
    },
    /** 获取数据字典 */
    getDicts() {
      queryDictsByCodes({
        parentCodes: 'WXPLX,YSLX,D0001,D0002,D0003,D0004,D0005,D0006,D0007'
      }).then((res) => {
        if (res.code === 1000) {
          this.modelList = res.data.WXPLX
          this.typeList = res.data.YSLX
          this.modelSubsList = res.data
          delete this.modelSubsList.WXPLX
          delete this.modelSubsList.YSLX
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    if (this.itemId) {
      getDangerousById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }
          this.addForm.carriageDate = new Date(this.addForm.carriageDate)
          this.choosedCph = this.cph

          delete this.addForm.createTime
          delete this.addForm.updateTime
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  },
  watch: {
    cph(curVal, oldVal) {
      this.choosedCph = this.cph
    }
  }
}
</script>

<style lang="scss" scoped>
.add-danger {
  @include themify() {
    .addForm-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .addForm-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
